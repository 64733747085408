import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { ListSubheader, List, Button, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/commons/contexts/SidebarContext';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import HomeIcon from '@mui/icons-material/Home';
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { CSTE_ROUTES } from 'src/commons/constants';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import ArticleIcon from '@mui/icons-material/Article';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { MenuWrapper } from 'src/commons/components/SidebarMenuWrapper/menuWrapper';
import { SubMenuWrapper } from 'src/commons/components/SidebarMenuWrapper/subMenuWrapper';
function SidebarMenuAdmin() {
    const { closeSidebar } = useContext(SidebarContext);
    return (_jsxs(MenuWrapper, { children: [_jsx(List, { component: "div", children: _jsx(SubMenuWrapper, { children: _jsx(List, { component: "div", children: _jsx(ListItem, { component: "div", children: _jsx(Button, { disableRipple: true, component: RouterLink, onClick: closeSidebar, to: CSTE_ROUTES.ADMINISTRATOR.OVERVIEW, startIcon: _jsx(HomeIcon, {}), children: "Overview" }) }) }) }) }), _jsx(List, { component: "div", subheader: _jsx(ListSubheader, { component: "div", disableSticky: true, children: "Accounts & permissions" }), children: _jsx(SubMenuWrapper, { children: _jsx(List, { component: "div", children: _jsx(ListItem, { component: "div", children: _jsx(Button, { disableRipple: true, component: RouterLink, onClick: closeSidebar, to: CSTE_ROUTES.ADMINISTRATOR.USERS.LIST, startIcon: _jsx(AccountCircleTwoToneIcon, {}), children: "Users" }) }) }) }) }), _jsx(List, { component: "div", subheader: _jsx(ListSubheader, { component: "div", disableSticky: true, children: "Tools" }), children: _jsx(SubMenuWrapper, { children: _jsxs(List, { component: "div", children: [_jsx(ListItem, { component: "div", children: _jsx(Button, { disableRipple: true, component: RouterLink, onClick: closeSidebar, to: CSTE_ROUTES.ADMINISTRATOR.DEVICES.LIST, startIcon: _jsx(TabletMacIcon, {}), children: "Devices" }) }), _jsx(ListItem, { component: "div", children: _jsx(Button, { disableRipple: true, component: RouterLink, onClick: closeSidebar, to: CSTE_ROUTES.ADMINISTRATOR.DOCUMENTS.LIST, startIcon: _jsx(ArticleIcon, {}), children: "Documents" }) }), _jsx(ListItem, { component: "div", children: _jsx(Button, { disableRipple: true, component: RouterLink, onClick: closeSidebar, to: CSTE_ROUTES.ADMINISTRATOR.UPLOADSCMM.UPLOADLIST, startIcon: _jsx(CloudDownloadIcon, {}), children: "Document Submission" }) })] }) }) })] }));
}
export default SidebarMenuAdmin;
