import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from "@mui/material";
import React, { useState } from "react";
function InputText({ field, type, fullWidth = true }) {
    const [changed, setChanged] = useState(false);
    const handleChange = (event) => {
        let newValue = event.target.value;
        if (field.upperCase) {
            newValue = newValue.toUpperCase();
        }
        field.valueChange(newValue);
        setChanged(!changed);
    };
    return (_jsx(TextField, { size: field.size ? field.size : 'medium', variant: field.variant ? field.variant : 'outlined', disabled: field.disabled, name: field.id, error: field.invalid, required: field.required, inputProps: { maxLength: field.maxLength }, type: type || 'text', id: field.id, label: field.label, fullWidth: fullWidth, autoComplete: "off", helperText: field.invalid ? field.errorText : '', value: field.value, onChange: handleChange, onKeyDown: e => {
            if (field.keyDown) {
                field.keyDown(e);
            }
        }, autoFocus: (field === null || field === void 0 ? void 0 : field.autoFocus) || false }));
}
export default InputText;
