import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SidebarLayout from 'src/pages/layout/SidebarLayout';
import BaseLayout from 'src/pages/layout/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Sidebar from 'src/pages/layout/SidebarLayout/Sidebar';
import SidebarMenu from 'src/pages/app-administrator/SidebarMenu';
import SidebarMenuWebReporting from 'src/pages/app-webreporting/SidebarMenu';
import SidebarMenuConfigurator from 'src/pages/app-configurator/SidebarMenu';
import { CSTE_LABELS } from 'src/commons/constants';
const Loader = Component => function (props) {
    return (_jsx(Suspense, { fallback: _jsx(SuspenseLoader, {}), children: _jsx(Component, Object.assign({}, props)) }));
};
// Pages
//----------------------------------------------------------------
// Commons pages
//----------------------------------------------------------------
const Login = Loader(lazy(() => import('src/pages/login')));
const PageAppSelector = Loader(lazy(() => import('src/pages/selector')));
const PageStatus500 = Loader(lazy(() => import('src/pages/status/500')));
const Status404 = Loader(lazy(() => import('src/pages/status/404')));
const PageStatus403 = Loader(lazy(() => import('src/pages/status/403')));
const PageSSOAuthenticated = Loader(lazy(() => import('src/pages/login/sso-authenticated')));
//----------------------------------------------------------------
// Admin pages
//----------------------------------------------------------------
const PageAdminOverview = Loader(lazy(() => import('src/pages/app-administrator/overview')));
const PageAdminUsers = Loader(lazy(() => import('src/pages/app-administrator/users/List')));
const PageAdminUsersEdit = Loader(lazy(() => import('src/pages/app-administrator/users/Edit')));
const PageAdminAccountSeetings = Loader(lazy(() => import('src/pages/app-administrator/accounts-permissions')));
const PageEditProfile = Loader(lazy(() => import('src/pages/app-administrator/accounts-permissions/pageEditProfile')));
const PageDevices = Loader(lazy(() => import('src/pages/app-administrator/devices')));
const PageDocuments = Loader(lazy(() => import('src/pages/app-administrator/documents')));
const PageUploadDocuments = Loader(lazy(() => import('src/pages/app-administrator/documents/upload/uploadFormPage')));
const PageUploadList = Loader(lazy(() => import('src/pages/app-administrator/documents/upload/uploadListPage')));
const PageUploadDetail = Loader(lazy(() => import('src/pages/app-administrator/documents/upload/uploadDetailPage')));
const PageNotificationForm = Loader(lazy(() => import('src/pages/app-administrator/notifications/notificationFormPage')));
const PageNotificationList = Loader(lazy(() => import('src/pages/app-administrator/notifications/notificationListPage')));
const PageHistory = Loader(lazy(() => import('src/pages/app-administrator/history')));
const PageAircraftList = Loader(lazy(() => import('src/pages/app-webreporting/fleet/PageAircraftList')));
//----------------------------------------------------------------
// WEB REPORTING pages
//----------------------------------------------------------------
const PageDataExplorer = Loader(lazy(() => import('src/pages/app-webreporting/data-explorer')));
const PageWROverview = Loader(lazy(() => import('src/pages/app-webreporting/overview')));
const PageSearchPN = Loader(lazy(() => import('src/pages/app-webreporting/search-pn')));
const PageDataMaintenance = Loader(lazy(() => import('src/pages/app-webreporting/maintenance')));
const PageDataMaintenanceV2 = Loader(lazy(() => import('src/pages/app-webreporting/maintenanceV2')));
const PageRfidInventoryList = Loader(lazy(() => import('src/pages/app-webreporting/reports/rfid-inventory-list')));
const PageRequestsViewer = Loader(lazy(() => import('src/pages/app-webreporting/requestsViewer')));
const PageRequestsViewerInfinite = Loader(lazy(() => import('src/pages/app-webreporting/requestsViewerInfiniteScroll')));
const PageWeeklyReport = Loader(lazy(() => import('src/pages/app-webreporting/reports/weekly-report')));
//----------------------------------------------------------------
// CONFIGURATOR
//----------------------------------------------------------------
const PageConfiguratorOverview = Loader(lazy(() => import('src/pages/app-configurator/overview')));
const PageConfiguratorAnalytics = Loader(lazy(() => import('src/pages/app-configurator/analytics')));
const PageConfiguratorTables = Loader(lazy(() => import('src/pages/app-configurator/Tables')));
const ListAircraftPage = Loader(lazy(() => import('src/pages/app-configurator/Tables/listAircraftPage')));
const PageListKpis = Loader(lazy(() => import('src/pages/app-configurator/Tables/pageListKPIS')));
const PageNarrowWideBodies = Loader(lazy(() => import('src/pages/app-configurator/Tables/pageNarrowWideBodies')));
const PageGeneralSettings = Loader(lazy(() => import('src/pages/app-configurator/general-settings')));
const PageCustomerInterop = Loader(lazy(() => import('src/pages/app-configurator/customer-interop')));
const PageEditCustomerInterop = Loader(lazy(() => import('src/pages/app-configurator/customer-interop/pageEditCustomerInterop')));
const PageDispatcherEnvs = Loader(lazy(() => import('src/pages/app-configurator/dispatcher/pageDispatcherEnvs')));
const PageDispatcherEnvsVars = Loader(lazy(() => import('src/pages/app-configurator/dispatcher/pageDispatcherEnvsVars')));
const PageManageTheme = Loader(lazy(() => import('src/pages/app-configurator/theme/manageTheme')));
const PageConfigureSSO = Loader(lazy(() => import('src/pages/app-configurator/sso/configureSSO')));
const PageManageCacheAndFiles = Loader(lazy(() => import('src/pages/app-configurator/filesAndCache/')));
const PageAdminDatabase = Loader(lazy(() => import('src/pages/app-configurator/database')));
const routes = [
    {
        path: '',
        element: _jsx(BaseLayout, {}),
        children: [
            {
                path: 'app-selector',
                element: _jsx(PageAppSelector, {}),
            },
            {
                path: 'sso-authenticated',
                element: _jsx(PageSSOAuthenticated, {}),
            },
            {
                path: '/',
                element: _jsx(Login, {}),
            },
            {
                path: '*',
                element: _jsx(Status404, {}),
            },
        ],
    },
    {
        path: 'status',
        element: _jsx(BaseLayout, {}),
        children: [
            {
                path: '',
                element: _jsx(Navigate, { to: "500", replace: true }),
            },
            {
                path: '500',
                element: _jsx(PageStatus500, {}), // <Login />, //
            },
            {
                path: '403',
                element: _jsx(PageStatus403, {}), // <Login />, //
            },
        ],
    },
    {
        path: 'administrator',
        element: (_jsx(SidebarLayout, { children: _jsx(Sidebar, { app_title: CSTE_LABELS.ADMINISTRATION.APPTITLE, children: _jsx(SidebarMenu, {}) }) })),
        children: [
            {
                path: '',
                element: _jsx(Navigate, { to: "overview", replace: true }),
            },
            {
                path: 'overview',
                element: _jsx(PageAdminOverview, {}),
            },
            {
                path: 'users',
                element: _jsx(PageAdminUsers, {}),
            },
            {
                path: 'users/edit',
                element: _jsx(PageAdminUsersEdit, {}),
            },
            {
                path: 'users/edit/:userId',
                element: _jsx(PageAdminUsersEdit, {}),
            },
            {
                path: 'accounts-permissions',
                element: _jsx(PageAdminAccountSeetings, {}),
            },
            {
                path: 'accounts-permissions/edit',
                element: _jsx(PageEditProfile, {}),
            },
            {
                path: 'accounts-permissions/edit/:profileId',
                element: _jsx(PageEditProfile, {}),
            },
            {
                path: 'data-explorer',
                element: _jsx(PageDataExplorer, {}),
            },
            {
                path: 'devices',
                element: _jsx(PageDevices, {}),
            },
            {
                path: 'documents',
                element: _jsx(PageDocuments, {}),
            },
            {
                path: 'uploadcmm/submit',
                element: _jsx(PageUploadDocuments, {}),
            },
            {
                path: 'uploadcmm/detail/:id',
                element: _jsx(PageUploadDetail, {}),
            },
            {
                path: 'uploadcmm',
                element: _jsx(PageUploadList, {}),
            },
            {
                path: 'history',
                element: _jsx(PageHistory, {}),
            },
            {
                path: 'notification/list',
                element: _jsx(PageNotificationList, {}),
            },
            {
                path: 'notification/create',
                element: _jsx(PageNotificationForm, {}),
            },
        ],
    },
    {
        path: 'webreporting',
        element: (_jsx(SidebarLayout, { children: _jsx(Sidebar, { app_title: CSTE_LABELS.WEBREPORTING.APPTITLE, children: _jsx(SidebarMenuWebReporting, {}) }) })),
        children: [
            {
                path: '',
                element: _jsx(Navigate, { to: "overview", replace: true }),
            },
            {
                path: 'overview',
                element: _jsx(PageWROverview, {}),
            },
            {
                path: 'reports/weekly-report',
                element: _jsx(PageWeeklyReport, {}),
            },
            {
                path: 'data-explorer',
                element: _jsx(PageDataExplorer, {}),
            },
            {
                path: 'search-pn',
                element: _jsx(PageSearchPN, {}),
            },
            {
                path: 'maintenanceV1',
                element: _jsx(PageDataMaintenance, {}),
            },
            {
                path: 'maintenance',
                element: _jsx(PageDataMaintenanceV2, {}),
            },
            {
                path: 'reports/rfid-inventories',
                element: _jsx(PageRfidInventoryList, {}),
            },
            {
                path: 'notification/list',
                element: _jsx(PageNotificationList, {}),
            },
            {
                path: 'notification/create',
                element: _jsx(PageNotificationForm, {}),
            },
            {
                path: 'dashboard/requestsViewer/:uuid',
                element: _jsx(PageRequestsViewer, {}),
            },
            {
                path: 'dashboard/requestsViewerScroll/:uuid',
                element: _jsx(PageRequestsViewerInfinite, {}),
            },
            {
                path: 'fleet/aircraftlist',
                element: _jsx(PageAircraftList, {}),
            },
        ],
    },
    {
        path: 'configurator',
        element: (_jsx(SidebarLayout, { children: _jsx(Sidebar, { app_title: CSTE_LABELS.AIRINTADMIN.APPTITLE, children: _jsx(SidebarMenuConfigurator, {}) }) })),
        children: [
            {
                path: '',
                element: _jsx(Navigate, { to: "overview", replace: true }),
            },
            {
                path: 'overview',
                element: _jsx(PageConfiguratorOverview, {}),
            },
            {
                path: 'analytics',
                element: _jsx(PageConfiguratorAnalytics, {}),
            },
            {
                path: 'data-explorer',
                element: _jsx(PageDataExplorer, {}),
            },
            {
                path: 'notification/list',
                element: _jsx(PageNotificationList, {}),
            },
            {
                path: 'notification/create',
                element: _jsx(PageNotificationForm, {}),
            },
            {
                path: 'tables/list',
                element: _jsx(PageConfiguratorTables, {}),
            },
            {
                path: 'tables/aircraft',
                element: _jsx(ListAircraftPage, {}),
            },
            {
                path: 'tables/kpis',
                element: _jsx(PageListKpis, {}),
            },
            {
                path: 'tables/narrow-wide-bodies',
                element: _jsx(PageNarrowWideBodies, {}),
            },
            {
                path: 'general-settings',
                element: _jsx(PageGeneralSettings, {}),
            },
            {
                path: 'customer-interop',
                element: _jsx(PageCustomerInterop, {}),
            },
            {
                path: 'customer-interop/edit',
                element: _jsx(PageEditCustomerInterop, {}),
            },
            {
                path: 'customer-interop/edit/:customerInteropId',
                element: _jsx(PageEditCustomerInterop, {}),
            },
            {
                path: 'dispatcher/envs',
                element: _jsx(PageDispatcherEnvs, {}),
            },
            {
                path: 'dispatcher/environmentvariables',
                element: _jsx(PageDispatcherEnvsVars, {}),
            },
            {
                path: 'theme/settings',
                element: _jsx(PageManageTheme, {}),
            },
            {
                path: 'sso/settings',
                element: _jsx(PageConfigureSSO, {}),
            },
            {
                path: 'database',
                element: _jsx(PageAdminDatabase, {}),
            },
            {
                path: 'accounts-permissions',
                element: _jsx(PageAdminAccountSeetings, {}),
            },
            {
                path: 'accounts-permissions/edit',
                element: _jsx(PageEditProfile, {}),
            },
            {
                path: 'accounts-permissions/edit/:profileId',
                element: _jsx(PageEditProfile, {}),
            },
            {
                path: 'cache-and-files',
                element: _jsx(PageManageCacheAndFiles, {}),
            },
        ],
    },
];
export default routes;
