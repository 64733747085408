import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/commons/contexts/SidebarContext';
import { Box, Drawer, alpha, styled, Divider, useTheme, Button, lighten, darken, } from '@mui/material';
import Logo from 'src/components/LogoSign';
import { CSTE_ROUTES } from 'src/commons/constants';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { useAuth } from 'src/commons/contexts/AuthContext';
const SidebarWrapper = styled(Box)(({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`);
function Sidebar({ children, app_title }) {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
    const closeSidebar = () => toggleSidebar();
    const theme = useTheme();
    const { hasPermission } = useAuth();
    let sum = 0;
    const moduleAdmin = hasPermission('administrator');
    const moduleReporting = hasPermission('webreporting');
    const moduleConfig = hasPermission('configurator');
    if (moduleAdmin) {
        sum += 1;
    }
    if (moduleReporting) {
        sum += 1;
    }
    if (moduleConfig) {
        sum += 1;
    }
    const showSwitchButton = sum > 1;
    return (_jsxs(_Fragment, { children: [_jsxs(SidebarWrapper, { sx: {
                    display: {
                        xs: 'none',
                        lg: 'inline-block',
                    },
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    background: theme.palette.mode === 'dark'
                        ? alpha(lighten(theme.header.background, 0.1), 0.5)
                        : darken(theme.colors.alpha.black[100], 0.5),
                    boxShadow: theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none',
                }, children: [_jsxs(Scrollbar, { children: [_jsx(Box, { mt: 3, children: _jsx(Box, { mx: 2, sx: {
                                        width: 52,
                                    }, children: _jsx(Logo, { title: app_title }) }) }), _jsx(Divider, { sx: {
                                    mt: theme.spacing(3),
                                    mx: theme.spacing(2),
                                    background: theme.colors.alpha.trueWhite[10],
                                } }), children] }), _jsx(Divider, { sx: {
                            background: theme.colors.alpha.trueWhite[10],
                        } }), showSwitchButton && (_jsx(Box, { p: 2, children: _jsx(Button, { variant: "contained", color: "secondary", size: "small", fullWidth: true, startIcon: _jsx(SwapHorizontalCircleIcon, {}), href: CSTE_ROUTES.COMMONS.APPSELECTOR, children: "Switch Application" }) }))] }), _jsx(Drawer, { sx: {
                    boxShadow: `${theme.sidebar.boxShadow}`,
                }, anchor: theme.direction === 'rtl' ? 'right' : 'left', open: sidebarToggle, onClose: closeSidebar, variant: "temporary", elevation: 9, children: _jsxs(SidebarWrapper, { sx: {
                        background: theme.palette.mode === 'dark'
                            ? theme.colors.alpha.white[100]
                            : darken(theme.colors.alpha.black[100], 0.5),
                    }, children: [_jsxs(Scrollbar, { children: [_jsx(Box, { mt: 3, children: _jsx(Box, { mx: 2, sx: {
                                            width: 52,
                                        }, children: _jsx(Logo, { title: app_title }) }) }), _jsx(Divider, { sx: {
                                        mt: theme.spacing(3),
                                        mx: theme.spacing(2),
                                        background: theme.colors.alpha.trueWhite[10],
                                    } }), children] }), showSwitchButton && (_jsx(Box, { p: 2, children: _jsx(Button, { variant: "contained", color: "secondary", size: "small", fullWidth: true, startIcon: _jsx(SwapHorizontalCircleIcon, {}), href: CSTE_ROUTES.COMMONS.APPSELECTOR, children: "Switch Application" }) }))] }) })] }));
}
export default Sidebar;
