var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LinearProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { CSTE_SSO_LOGIN } from 'src/commons/constants';
import { AISUserService } from 'src/commons/services/dataServices';
import { useTheme } from '@mui/material/styles';
import { AISCacheService } from 'src/commons/services/cacheService';
import { useState } from 'react';
import DividerWrapper from '../../Typography/Divider-wrapper';
function SSOLogin() {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const customer = AISCacheService.getSSOCustom();
    const clickSSOLogin = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        AISCacheService.removeTokenIsExpired();
        yield AISUserService.setFrontUrlInSession(window.location.origin);
        let redirectUri = '';
        if (customer === 'habile') {
            const { data: response } = yield AISUserService.getAuthUrlSSOHabile();
            if (response.status === 200) {
                redirectUri = response.results[0].redirectUri;
            }
            else {
                alert('An error occur on Habile SSO redirection');
            }
        }
        else {
            redirectUri = AISUserService.getAuthUrlSSO(customer);
        }
        window.location.href = redirectUri;
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    });
    return customer && Object.prototype.hasOwnProperty.call(CSTE_SSO_LOGIN, customer) ? (_jsxs(_Fragment, { children: [_jsxs(Stack, { direction: 'column', sx: { ':hover': { cursor: 'pointer' } }, border: `1px solid ${theme.colors.primary.main}`, p: 3, borderRadius: 1, onClick: clickSSOLogin, justifyItems: 'center', justifyContent: 'center', children: [_jsx("img", { src: CSTE_SSO_LOGIN[customer].image, height: 30 }), _jsx(Typography, { variant: "body1", color: theme.colors.primary.light, fontWeight: "normal", children: loading ? 'Connecting...' : CSTE_SSO_LOGIN[customer].label }), loading && _jsx(LinearProgress, {})] }), _jsx(DividerWrapper, { text: 'or', paddingBottom: 5, paddingTop: 5 })] })) : (_jsx(_Fragment, {}));
}
export default SSOLogin;
