import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, styled, Card, CardActions, CardContent, CardMedia, LinearProgress, } from '@mui/material';
const MainContent = styled(Box)(() => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`);
const TopWrapper = styled(Box)(({ theme }) => `
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(6)};
`);
function StatusLoading() {
    return (_jsx(MainContent, { children: _jsx(TopWrapper, { children: _jsxs(Card, { sx: { maxWidth: 500 }, children: [_jsx(CardMedia, { sx: { height: 200, width: 400 }, image: "/static/images/cards/loading-banner.png", title: "air int laoding photo" }), _jsx(CardContent, { children: _jsx(Typography, { gutterBottom: true, variant: "h5", component: "div", children: "Loading..." }) }), _jsx(CardActions, { children: _jsx(Box, { sx: { width: '100%' }, children: _jsx(LinearProgress, {}) }) })] }) }) }));
}
export default StatusLoading;
