var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip, tooltipClasses, styled, useTheme, Typography, Grid, Divider, } from '@mui/material';
import { Link } from 'react-router-dom';
import LogoAirInt from 'src/commons/components/Logo/LogoAirIntServices';
import { CSTE_LABELS } from 'src/commons/constants';
import { AISCacheService } from 'src/commons/services/cacheService';
const LogoWrapper = styled(Link)(({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 260px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`);
const TooltipWrapper = styled((_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx(Tooltip, Object.assign({}, props, { classes: { popper: className } })));
})(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.colors.alpha.trueWhite[100],
        color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 'bold',
        borderRadius: theme.general.borderRadiusSm,
        boxShadow: '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.colors.alpha.trueWhite[100],
    },
}));
function Logo({ title }) {
    const theme = useTheme();
    const hrefModule = AISCacheService.getBaseModuleHref();
    return (_jsx(TooltipWrapper, { title: `${CSTE_LABELS.COMMONS.AIS_SOFTWARE_TITLE} - ${title}`, arrow: true, children: _jsx(LogoWrapper, { to: hrefModule, children: _jsxs(Grid, { container: true, direction: "column", alignItems: "center", pr: 4, children: [_jsx(Grid, { item: true, children: _jsx(LogoAirInt, { width: 220, height: 70, textColor: theme.colors.alpha.trueWhite[100] }) }), _jsxs(Grid, { item: true, mt: 1, textAlign: "center", sx: { mt: 2 }, width: '100%', children: [_jsx(Divider, { sx: {
                                    mb: theme.spacing(3),
                                    background: theme.colors.alpha.trueWhite[10],
                                } }), _jsx(Typography, { variant: "h4", gutterBottom: true, color: theme.colors.alpha.white[70], children: title })] })] }) }) }));
}
export default Logo;
