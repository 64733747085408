import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'nprogress/nprogress.css';
import App from './App';
import { SidebarProvider } from './commons/contexts/SidebarContext';
import * as serviceWorker from './serviceWorker';
ReactDOM.render(_jsx(HelmetProvider, { children: _jsx(SidebarProvider, { children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }) }), document.getElementById('root'));
serviceWorker.unregister();
