/* eslint-disable no-restricted-syntax */
import { useReducer, useState } from 'react';
export class CForm {
    constructor(fields) {
        this.form = {};
        this.form = fields;
    }
    getForm() {
        return this.form;
    }
    setForm(form) {
        this.form = form;
    }
    getField(fieldName) {
        return this.form[fieldName];
    }
    setFieldValue(fieldName, value) {
        this.form[fieldName].value = value;
    }
    getFieldValue(fieldName) {
        return this.form[fieldName].value;
    }
    validateForm() {
        for (const property in this.form) {
            if (Object.prototype.hasOwnProperty.call(this.form, property)) {
                // Mettez votre code ici
                if (this.form[property].required) {
                    console.log(property);
                }
            }
        }
    }
    valueIsNull(fieldName) {
        const value = this.getFieldValue(fieldName);
        return !value || value === undefined || !!(typeof value === 'string' && value.length <= 0);
    }
}
// useForm functional componen
export const useForm = (callback, initialState = {}, checkSpecificsBeforeSubmit) => {
    const [values, setValues] = useState(initialState);
    const getField = (fieldName) => {
        if (Object.prototype.hasOwnProperty.call(values, fieldName)) {
            return values[fieldName];
        }
        alert(`Internal Error ! the field <${fieldName}> does not exist in the form.`);
        return null;
    };
    function valueIsNull(value) {
        if (typeof value === 'number' || typeof value === 'boolean') {
            return value === undefined;
        }
        return !value || value === undefined || (typeof value === 'string' && value.length <= 0);
    }
    // const fieldValueIsNull = (fieldName: string): boolean => {
    //   const value = getFieldValue(fieldName);
    //   return valueIsNull(value);
    // };
    // Forcing to render
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    // onChange
    const onChange = (fieldName, value, doUpdate = false) => {
        const field = getField(fieldName);
        if (!field) {
            return;
        }
        if (field.invalid && field.required && !valueIsNull(value)) {
            field.invalid = false;
            field.errorText = ``;
        }
        field.value = value;
        values[fieldName] = field;
        // setValues({ ...values, field});
        if (doUpdate) {
            forceUpdate();
        }
    };
    // onSubmit
    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        if (!onValidateForm()) {
            return;
        }
        callback(); // triggering the callback
    };
    const onValidateForm = () => {
        let formIsValid = true;
        for (const property in values) {
            if (Object.prototype.hasOwnProperty.call(values, property)) {
                const field = getField(property);
                if (!field) {
                    // eslint-disable-next-line no-continue
                    continue;
                }
                if (field.required && valueIsNull(field.value)) {
                    formIsValid = false;
                    field.invalid = true;
                    field.errorText = `<${values[property].label}> is required`;
                    setValues(Object.assign(Object.assign({}, values), { field }));
                }
                if (field.maxLength && field.value.length > field.maxLength) {
                    formIsValid = false;
                    field.invalid = true;
                    field.errorText = `<${values[property].label}> is too long`;
                    setValues(Object.assign(Object.assign({}, values), { field }));
                }
            }
        }
        if (!formIsValid) {
            return false;
        }
        if (checkSpecificsBeforeSubmit) {
            return checkSpecificsBeforeSubmit();
        }
        return true;
    };
    const updateValues = (newValues) => {
        setValues(newValues);
        forceUpdate();
    };
    return {
        onChange,
        onSubmit,
        onValidateForm,
        updateValues,
        values,
    };
};
/*
label='Login' value={credential.login} valueChange={ (e:string)=> handleValueChange( "login", e)}
*/
