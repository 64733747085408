var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { alpha, Badge, Box, CircularProgress, Divider, IconButton, List, ListItem, Popover, Tooltip, Typography, } from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MessageIcon from '@mui/icons-material/Message';
import { styled } from '@mui/material/styles';
import { formatDistance } from 'date-fns';
import { CSTE_ROUTES } from 'src/commons/constants';
import LinkButton from 'src/commons/components/Buttons/LinkButton';
import { AISNotificationService } from 'src/commons/services/dataServices';
import AISRouterClass from 'src/commons/ais-classes/router/ais-router';
const NotificationsBadge = styled(Badge)(({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`);
function HeaderNotifications() {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const [nbNotifications] = useState(0);
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const router = new AISRouterClass();
    useEffect(() => {
        getListNotification();
    }, []);
    const getListNotification = () => __awaiter(this, void 0, void 0, function* () {
        try {
            const { data: res } = yield AISNotificationService.getListNotification();
            if (res.status === 200) {
                setNotifications(res.results);
                setLoading(false);
            }
        }
        catch (error) {
            console.error(error);
            setLoading(false);
        }
    });
    const handleOpen = () => {
        getListNotification();
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const notificationComponent = (notification, index) => {
        if (index >= 3) {
            return null;
        }
        return (_jsxs("div", { children: [_jsx(ListItem, { sx: {
                        p: 1,
                        minWidth: 350,
                        display: { xs: 'block', sm: 'flex' },
                    }, children: _jsxs(Box, { flex: "1", children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", children: [_jsx(Typography, { sx: { fontWeight: 'bold' }, children: notification.title }), _jsx(Typography, { variant: "caption", sx: { textTransform: 'none' }, children: formatDistance(new Date(notification.date), new Date(), {
                                            addSuffix: true,
                                        }) })] }), _jsxs(Typography, { component: "span", variant: "body2", color: "text.secondary", children: [' ', notification.subtitle] })] }) }), _jsx(Divider, {})] }, `notif-${index}`));
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { arrow: true, title: "Messages", children: _jsx(IconButton, { color: "primary", ref: ref, onClick: handleOpen, children: _jsx(NotificationsBadge, { badgeContent: nbNotifications, anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        }, children: _jsx(MessageIcon, {}) }) }) }), _jsxs(Popover, { anchorEl: ref.current, onClose: handleClose, open: isOpen, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, children: [_jsxs(Box, { sx: { p: 2 }, display: "flex", alignItems: "center", justifyContent: "space-between", children: [_jsx(Typography, { variant: "h5", children: "Messages" }), _jsx(IconButton, { onClick: () => {
                                    router.navigate(CSTE_ROUTES.COMMONS.NOTIFICATION.CREATE);
                                    setOpen(false);
                                }, sx: {
                                    p: 1,
                                }, size: "small", color: "primary", children: _jsx(AddCircleOutlineIcon, { fontSize: "small" }) })] }), _jsx(Divider, {}), _jsxs(List, { sx: { p: 0 }, children: [loading && (_jsxs(Box, { sx: { display: 'flex' }, p: 2, alignItems: 'center', children: [_jsx(CircularProgress, { size: '16px' }), _jsx(Typography, { ml: 2, sx: { fontWeight: 'bold' }, children: "Loading in progress..." })] })), notifications.length > 0 &&
                                notifications.map((notification, index) => notificationComponent(notification, index)), _jsx(Box, { display: "flex", justifyContent: "end", p: 1, children: _jsx(LinkButton, { onClick: () => {
                                        setOpen(false);
                                    }, label: "Show more", href: CSTE_ROUTES.COMMONS.NOTIFICATION.LIST }) })] })] })] }));
}
export default HeaderNotifications;
