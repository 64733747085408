var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from 'react';
import { Box, alpha, Stack, lighten, Divider, IconButton, Tooltip, styled, useTheme, Dialog, DialogContent, DialogTitle, Alert, Hidden, } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/commons/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useAuth } from 'src/commons/contexts/AuthContext';
import FormLogin from 'src/commons/components/Login/Form';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';
const HeaderWrapper = styled(Box)(({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 1)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            
            width: auto;
        }
`);
function Header() {
    const { sidebarToggle, toggleSidebar, sidebarHidden } = useContext(SidebarContext);
    const theme = useTheme();
    const intervalRef = useRef(null);
    const { check } = useAuth();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
        // checkConnection()
        window.location.reload();
    };
    const checkConnection = () => __awaiter(this, void 0, void 0, function* () {
        check(checkFailed);
    });
    const checkFailed = () => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
        startConfirmChecked();
    };
    const startConfirmChecked = () => {
        setTimeout(() => {
            checkConnectionConfirm();
        }, 10000);
    };
    const checkConfirmFailed = () => {
        setOpen(true);
    };
    const checkConfirmSuccess = () => {
        intervalRef.current = setInterval(checkConnection, 60000); // 60 secondes
    };
    const checkConnectionConfirm = () => __awaiter(this, void 0, void 0, function* () {
        check(checkConfirmFailed, checkConfirmSuccess);
    });
    useEffect(() => {
        // intervalRef.current = setInterval(checkConnection, 60000);
        // return () => {
        //   if (intervalRef.current !== null) {
        //     clearInterval(intervalRef.current);
        //   }
        // };
    }, []);
    return (_jsxs(HeaderWrapper, { display: "flex", alignItems: "center", sx: {
            boxShadow: theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(lighten(theme.colors.primary.main, 0.7), 0.15)}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 8px -3px ${alpha(theme.colors.alpha.black[100], 0.2)}, 0px 5px 22px -4px ${alpha(theme.colors.alpha.black[100], 0.1)}`,
            [theme.breakpoints.up('lg')]: {
                left: sidebarHidden ? '0' : `${theme.sidebar.width}`,
            },
        }, children: [_jsx(Stack, { direction: "row", divider: _jsx(Divider, { orientation: "vertical", flexItem: true }), alignItems: "center", spacing: 2, children: _jsx(HeaderMenu, {}) }), _jsxs(Box, { display: "flex", alignItems: "center", children: [_jsxs(Hidden, { xsDown: true, smDown: true, children: [_jsx(HeaderButtons, {}), _jsx(HeaderUserbox, {})] }), _jsx(Box, { component: "span", sx: {
                            ml: 2,
                            display: { lg: 'none', xs: 'inline-block' },
                        }, children: _jsx(Tooltip, { arrow: true, title: "Toggle Menu", children: _jsx(IconButton, { color: "primary", onClick: toggleSidebar, children: !sidebarToggle ? (_jsx(MenuTwoToneIcon, { fontSize: "large" })) : (_jsx(CloseTwoToneIcon, { fontSize: "large" })) }) }) })] }), _jsxs(Dialog, { open: open, maxWidth: 'lg', onClose: undefined, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", children: [_jsx(DialogTitle, { id: "alert-dialog-title", sx: { backgroundColor: theme.palette.primary.dark, color: theme.colors.alpha.white[100] }, children: 'Expired session' }), _jsx(Divider, {}), _jsxs(DialogContent, { sx: { width: 800 }, children: [_jsxs(Alert, { severity: "error", sx: { mb: 2 }, children: ["Your session has expired due to inactivity, ", _jsx("strong", { children: "please log in again." })] }), _jsx(FormLogin, { handleLoginSuccess: handleClose })] })] })] }));
}
export default Header;
