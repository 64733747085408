var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Box, useTheme } from '@mui/material';
function Scrollbar(_a) {
    var { className, children } = _a, rest = __rest(_a, ["className", "children"]);
    const theme = useTheme();
    return (_jsx(Scrollbars, Object.assign({ autoHide: true, renderThumbVertical: () => (_jsx(Box, { sx: {
                width: 5,
                background: `${theme.colors.alpha.black[10]}`,
                borderRadius: `${theme.general.borderRadiusLg}`,
                transition: `${theme.transitions.create(['background'])}`,
                '&:hover': {
                    background: `${theme.colors.alpha.black[30]}`,
                },
            } })) }, rest, { children: children })));
}
Scrollbar.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
export default Scrollbar;
