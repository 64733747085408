import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
function InputSelect({ field, onChange, fullWidth = true }) {
    const [currentField, setField] = useState(field);
    const handleChange = (event) => {
        if (onChange) {
            onChange(event.target.value);
        }
        currentField.valueChange(event.target.value);
        setField(Object.assign(Object.assign({}, currentField), { value: event.target.value }));
    };
    return (_jsx(TextField, { id: field.id, sx: field.sx ? field.sx : {}, label: field.label, select: true, defaultValue: field.defaultValue ? field.defaultValue : '', error: field.invalid, required: field.required, size: field.size ? field.size : 'medium', variant: field.variant ? field.variant : 'outlined', fullWidth: fullWidth, disabled: field.disabled, value: field.value, helperText: field.invalid ? field.errorText : '', autoFocus: (field === null || field === void 0 ? void 0 : field.autoFocus) || false, onChange: handleChange, SelectProps: {
            MenuProps: {
                PaperProps: {
                    sx: {
                        zIndex: 9999,
                    },
                },
            },
        }, children: field.options &&
            field.options.map(option => (_jsx(MenuItem, { value: option.id, children: option.name }, option.id))) }));
}
export default InputSelect;
