import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert, Snackbar } from '@mui/material';
import { createContext, useContext, useState, useMemo } from 'react';
const snackBarContextDefaultValues = {
    showSnackBar: () => { },
};
const SnackBarContext = createContext(snackBarContextDefaultValues);
export function useSnackBar() {
    return useContext(SnackBarContext);
}
export function SnackBarProvider({ children }) {
    const [snackbarParam, setSnackbarParam] = useState({
        open: false,
        message: '',
        severity: 'success',
    });
    const showSnackBar = (message, severity) => {
        setSnackbarParam({ open: true, message, severity: severity || 'success' });
    };
    const handleClose = (event, reason) => {
        setSnackbarParam({ open: false, message: '', severity: 'success' });
    };
    const contextValue = useMemo(() => ({
        showSnackBar,
    }), []);
    return (_jsxs(SnackBarContext.Provider, { value: contextValue, children: [children, _jsx(Snackbar, { open: snackbarParam.open, autoHideDuration: 5000, onClose: handleClose, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, children: _jsx(Alert, { onClose: handleClose, severity: snackbarParam.severity, variant: "filled", sx: { width: '100%' }, children: snackbarParam.message }) })] }));
}
