import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Link } from '@mui/material';
import AISRouterClass from 'src/commons/ais-classes/router/ais-router';
function LinkButton({ href, label, icone, onClick, openInNewTab }) {
    const router = new AISRouterClass();
    const handleClick = () => {
        if (openInNewTab) {
            const win = window.open(href, '_blank');
            if (win) {
                win.focus();
            }
            return;
        }
        if (href) {
            router.navigate(href);
        }
        if (onClick) {
            onClick(null);
        }
    };
    return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    _jsx(Link, { children: _jsx(Button, { onClick: handleClick, sx: { mt: { xs: 2, md: 0 } }, size: "small", variant: "text", endIcon: icone || null, children: label }) }));
}
export default LinkButton;
