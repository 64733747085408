import { jsx as _jsx } from "react/jsx-runtime";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { InputAdornment, IconButton, TextField } from "@mui/material";
import React, { useState } from 'react';
function InputPassword({ field }) {
    const [currentField, setField] = useState(field);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(show => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleChange = (event) => {
        currentField.valueChange(event.target.value);
        setField(Object.assign(Object.assign({}, currentField), { value: event.target.value }));
    };
    return (_jsx(TextField, { required: field.required, error: field.invalid, type: showPassword ? 'text' : 'password', id: field.id, name: field.id, label: field.label, fullWidth: true, autoComplete: "off", size: field.size ? field.size : 'medium', variant: field.variant ? field.variant : 'outlined', InputProps: {
            endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword, children: showPassword ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) }) })),
        }, value: field.value, onChange: handleChange, helperText: field.invalid ? field.errorText : '', onKeyDown: e => {
            if (field.keyDown) {
                field.keyDown(e);
            }
        }, autoFocus: (field === null || field === void 0 ? void 0 : field.autoFocus) || false }));
}
export default InputPassword;
