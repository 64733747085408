import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';
function SuspenseLoader() {
    useEffect(() => {
        NProgress.start();
        return () => {
            NProgress.done();
        };
    }, []);
    return (_jsx(Box, { sx: {
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%'
        }, display: "flex", alignItems: "center", justifyContent: "center", children: _jsx(CircularProgress, { size: 64, disableShrink: true, thickness: 3 }) }));
}
export default SuspenseLoader;
