import { jsx as _jsx } from "react/jsx-runtime";
import { useState, createContext } from 'react';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext({});
export function SidebarProvider({ children }) {
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [sidebarHidden, setSidebarHidden] = useState(false);
    const toggleSidebar = () => {
        setSidebarToggle(!sidebarToggle);
    };
    const closeSidebar = () => {
        setSidebarToggle(false);
    };
    const hideSidebar = () => {
        setSidebarHidden(true);
    };
    const ShowSidebar = () => {
        setSidebarHidden(false);
    };
    return (_jsx(SidebarContext.Provider
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    , { 
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value: { sidebarToggle, sidebarHidden, toggleSidebar, closeSidebar, hideSidebar, ShowSidebar }, children: children }));
}
