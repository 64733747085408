import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { Avatar, Box, Button, Divider, Hidden, lighten, Popover, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { CSTE_ROUTES } from 'src/commons/constants';
import { useAuth } from 'src/commons/contexts/AuthContext';
const UserBoxButton = styled(Button)(({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`);
const MenuUserBox = styled(Box)(({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`);
const UserBoxText = styled(Box)(({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`);
const UserBoxLabel = styled(Typography)(({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`);
const UserBoxDescription = styled(Typography)(({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`);
function HeaderUserbox() {
    const { user, logout } = useAuth();
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleLogout = () => {
        logout();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(UserBoxButton, { color: "secondary", ref: ref, onClick: handleOpen, children: [_jsx(Avatar, { variant: "rounded", alt: user.name, src: user.avatar }), _jsx(Hidden, { mdDown: true, children: _jsxs(UserBoxText, { children: [_jsx(UserBoxLabel, { variant: "body1", children: user.name }), _jsx(UserBoxDescription, { variant: "body2", children: user.jobtitle })] }) }), _jsx(Hidden, { smDown: true, children: _jsx(ExpandMoreTwoToneIcon, { sx: { ml: 1 } }) })] }), _jsxs(Popover, { anchorEl: ref.current, onClose: handleClose, open: isOpen, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, children: [_jsxs(MenuUserBox, { sx: { minWidth: 210 }, display: "flex", children: [_jsx(Avatar, { variant: "rounded", alt: user.name, src: user.avatar }), _jsxs(UserBoxText, { children: [_jsx(UserBoxLabel, { variant: "body1", children: user.name }), _jsx(UserBoxDescription, { variant: "body2", children: user.jobtitle })] })] }), _jsx(Divider, { sx: { mb: 0 } }), _jsx(Box, { sx: { m: 1 }, children: _jsxs(Button, { color: "primary", fullWidth: true, onClick: handleLogout, href: CSTE_ROUTES.COMMONS.LOGIN, children: [_jsx(LockOpenTwoToneIcon, { sx: { mr: 1 } }), "Sign out"] }) })] })] }));
}
export default HeaderUserbox;
