var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dialog, DialogTitle, Divider, DialogContent, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createContext, useContext, useState, useEffect, useMemo, useRef } from 'react';
import FormLogin from '../components/Login/Form';
import { AISCacheService } from '../services/cacheService';
import { AISUserService } from '../services/dataServices';
// Créer un contexte pour les deux timers
const TimerContext = createContext({
    startTimerTokenExpired: () => { },
    showLoginDialog: () => { },
    startTimerHeathCheck: () => { },
    stopTimerTokenExpired: () => { },
    stopTimerHeathCheck: () => { },
});
export function useTimers() {
    return useContext(TimerContext);
}
export function TimerProvider({ children }) {
    const [open, setOpen] = useState(false);
    const [openHealthCheck, setOpenHealthCheck] = useState(false);
    const theme = useTheme();
    const idTimerTokenExpired = useRef(null);
    const idTimerHealthCheck = useRef(null);
    const [title, setTitle] = useState('Your session has expired due to inactivity');
    const showLoginDialog = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        AISCacheService.removeTokenIsExpired();
        window.location.reload();
    };
    /** ***********************************
     *
     * Timer for the check Expiration token
     *
     ************************************* */
    const startTimerTokenExpired = () => {
        if (!idTimerTokenExpired.current) {
            idTimerTokenExpired.current = setInterval(() => {
                if (!openHealthCheck && window.location.pathname !== '/') {
                    setOpen(AISCacheService.tokenIsExpired());
                }
            }, 1000);
        }
    };
    const stopTimerTokenExpired = () => {
        if (idTimerTokenExpired.current) {
            clearInterval(idTimerTokenExpired.current);
        }
    };
    /** ********************************
     *
     * Timer for the check connexion
     *
     ********************************* */
    const startTimerHeathCheck = () => {
        if (!idTimerHealthCheck.current) {
            idTimerHealthCheck.current = setInterval(() => __awaiter(this, void 0, void 0, function* () {
                if (!open &&
                    !AISCacheService.tokenIsExpired() &&
                    !openHealthCheck &&
                    window.location.pathname !== '/') {
                    const { data: resCheck } = yield AISUserService.check();
                    if (resCheck.status === 200) {
                        if (resCheck.results[0].checkStatus !== 'OK') {
                            AISCacheService.setTokenIsExpired();
                            setTitle('Your session has expired due to inactivity');
                            setOpenHealthCheck(true);
                            stopTimerHeathCheck();
                        }
                    }
                    else {
                        AISCacheService.setTokenIsExpired();
                        setTitle('Server connection error');
                        setOpenHealthCheck(true);
                        stopTimerHeathCheck();
                    }
                }
            }), 60000);
        }
    };
    const stopTimerHeathCheck = () => {
        if (idTimerHealthCheck.current) {
            clearInterval(idTimerHealthCheck.current);
        }
    };
    // Mémorisation de l'objet contextuel
    const contextValue = useMemo(() => ({
        showLoginDialog,
        startTimerTokenExpired,
        startTimerHeathCheck,
        stopTimerTokenExpired,
        stopTimerHeathCheck,
    }), []);
    useEffect(() => {
        startTimerTokenExpired();
        startTimerHeathCheck();
        return () => {
            stopTimerTokenExpired();
            stopTimerHeathCheck();
        };
    }, []);
    return (_jsxs(TimerContext.Provider, { value: contextValue, children: [children, _jsxs(Dialog, { open: open || openHealthCheck, maxWidth: 'lg', onClose: undefined, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", children: [_jsx(DialogTitle, { id: "alert-dialog-title", sx: { backgroundColor: theme.palette.primary.dark, color: theme.colors.alpha.white[100] }, children: 'Expired session' }), _jsx(Divider, {}), _jsxs(DialogContent, { sx: { width: 800 }, children: [_jsxs(Alert, { severity: "error", sx: { mb: 2 }, children: [title, ", ", _jsx("strong", { children: "please log in again." })] }), _jsx(FormLogin, { handleLoginSuccess: handleClose })] })] })] }));
}
