import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { AISCacheService } from 'src/commons/services/cacheService';
import { themeCreator } from './base';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ThemeContext = React.createContext((themeName) => { });
function ThemeProviderWrapper(props) {
    const curThemeName = AISCacheService.getTheme() || 'AISDefaultTheme';
    const [themeName, _setThemeName] = useState(curThemeName);
    const theme = themeCreator(themeName);
    const setThemeName = useCallback((themeName) => {
        localStorage.setItem('AIS-appTheme', themeName);
        _setThemeName(themeName);
    }, []);
    return (_jsx(StylesProvider, { injectFirst: true, children: _jsx(ThemeContext.Provider, { value: setThemeName, children: _jsx(ThemeProvider, { theme: theme, children: props.children }) }) }));
}
export default ThemeProviderWrapper;
