import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from "@mui/material";
import React, { useState } from "react";
import InputMask from "react-input-mask";
function InputTextMask({ field, type, fullWidth = true }) {
    const [changed, setChanged] = useState(false);
    const handleChange = (event) => {
        let newValue = event.target.value;
        if (field.upperCase) {
            newValue = newValue.toUpperCase();
        }
        field.valueChange(newValue);
        setChanged(!changed);
    };
    return (_jsx(InputMask, { mask: field.mask, value: field.value, disabled: field.disabled, maskChar: " ", formatChars: field.formatChars, onChange: handleChange, autoComplete: "off", placeHolder: "ABC-1234", children: () => (_jsx(TextField, { id: field.id, fullWidth: fullWidth, variant: field.variant ? field.variant : 'outlined', size: field.size ? field.size : 'medium', required: field.required, helperText: field.invalid ? field.errorText : '', label: field.label, type: type || 'text', name: field.id, error: field.invalid, autoComplete: "off", onKeyDown: e => {
                if (field.keyDown) {
                    field.keyDown(e);
                }
            }, autoFocus: (field === null || field === void 0 ? void 0 : field.autoFocus) || false })) }));
}
export default InputTextMask;
