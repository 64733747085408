/* eslint-disable no-param-reassign */
import { useNavigate, useLocation } from 'react-router-dom';
class AISRouterClass {
    constructor() {
        this.routerNavigate = useNavigate();
        this.routerLocation = useLocation();
    }
    getLocation() {
        return this.routerLocation.pathname;
    }
    navigate(url) {
        if (url.includes('%MODULE%')) {
            url = url.replace('%MODULE%', this.getModule());
        }
        this.routerNavigate(url);
    }
    navigateInModule() {
        this.routerNavigate(`/${this.getModule}/`);
    }
    back() {
        this.routerNavigate(-1);
    }
    reload() {
        this.routerNavigate(0);
    }
    getModule() {
        const location = this.getLocation().split('/');
        return location[1];
    }
}
export default AISRouterClass;
