import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Divider, styled } from "@mui/material";
const DividerWrapperTheme = styled(Divider)(({ theme }) => `
        .MuiDivider-wrapper {
          border-radius: ${theme.general.borderRadiusSm};
          text-transform: none;
          background: ${theme.palette.background.default};
          font-size: ${theme.typography.pxToRem(13)};
          color: ${theme.colors.alpha.black[50]};
        }
  `);
function DividerWrapper({ text, paddingTop, paddingBottom }) {
    return (_jsx(Box, { sx: { paddingTop: paddingTop || 0, paddingBottom: paddingBottom || 0 }, children: _jsx(DividerWrapperTheme, { children: text }) }));
}
export default DividerWrapper;
