import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
function BaseLayout({ children }) {
    return (_jsx(Box, { sx: {
            flex: 1,
            height: '100%',
        }, children: children || _jsx(Outlet, {}) }));
}
BaseLayout.propTypes = {
    children: PropTypes.node,
};
export default BaseLayout;
